export const QUILL_TOOLBAR = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ color: [] }, { background: [] }],
  ['link'],
  ['clean']
];

export const QUILL_FORMATS = [
  'header', 'bold', 'italic', 'underline', 'list', 'color', 'background', 'link'
];

export default {
  QUILL_TOOLBAR,
  QUILL_FORMATS
};
